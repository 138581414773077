/* ########## COMPONENT IMPORTS ########## */
import HBImage from '@/components/packages/hbimage';
import PropertyCardStarRatingMaterial from '@/components/packages/property-card-star-rating-material';
import CommonCardContainer from '@/components/packages/common-card-container';
import useMaterialMediaQuery from '@/components/packages/use-material-media-query';

/* ########## MATERIAL UI IMPORTS ########## */
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import React from 'react';
import ClassNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    borderRadius: '4px',
    padding: 0,
  },
  tooltip: {
    position: 'absolute',
    content: '""',
    left: '50%',
    bottom: -12,
    border: 'solid',
    borderWidth: '12px 6px 0 6px',
    borderColor: '#DEDEFF transparent',
  },
  developerDiv: {
    position: 'relative',
    border: '1px solid #D2DEEB',
    borderRadius: '15px',
    overflow: 'hidden',
    background: '#FFFFFF',
    '&:hover': {
      border: '1px solid #899BAF',
    },
  },
  iconFilled: {
    color: '#171717',
    marginLeft: '-1px',
    marginBottom: '15px',
    fontSize: '17px',
    paddingTop: '5px',
  },
  iconBorder: {
    color: '#171717',
  },
  developerLogo: {
    alignItems: 'center',
    minHeight: '72px',
    background: 'white',
    width: '100%',
    padding: '0.5rem',
    paddingTop: '1rem',
    paddingBottom: '0',
    display: 'flex',
    justifyContent: 'space-around',
    //borderRadius: '10px',

    '& h4': {
      fontWeight: 'bold !important',
      height: '45px',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
  },
}));

const TempCard = props => {
  const {
    developer_body,
    developer_name,
    developer_logo,
    star_rating,
    rootClass,
    redirectURL,
  } = props;
  const classes = useStyles();
  const [small, medium, large] = useMaterialMediaQuery();
  return (
    <section
      className={ClassNames([classes.root, 'mob-developers-card', rootClass])}>
      <CommonCardContainer url={redirectURL} target="_blank">
        <div className={classes.developerDiv}>
          <Grid
            container
            // justifyContent="space-between"
            // alignItems="center"
            // spacing={1}
          >
            <div
              className={ClassNames([
                classes.developerLogo,
                'developerslogomain',
              ])}>
              {(medium || large) && (
                <div style={{ marginRight: '10px' }}>
                  <HBImage
                    width="170px"
                    height="80px"
                    // src={developer_logo}
                    src={`${developer_logo}?tr=w-170px,h-80px`}
                    alt={developer_name.props.text}
                    title={developer_name.props.text}
                  />
                </div>
              )}

              {small && (
                <div style={{ marginRight: '10px' }}>
                  <HBImage
                    width="105px"
                    height="49px"
                    src={developer_logo}
                    alt={developer_name.props.children}
                    title={developer_name.props.children}
                  />
                </div>
              )}

              <div style={{ height: '84px', textAlign: 'center' }}>
                {developer_name}
                <PropertyCardStarRatingMaterial
                  rating={star_rating}
                  precision={0.5}
                  root_class={classes.iconFilled}
                  emptyIcon={classes.iconBorder}
                />
              </div>
              <br></br>
            </div>
            <br></br>
            {developer_body}
          </Grid>
        </div>
      </CommonCardContainer>
    </section>
  );
};

export default TempCard;
