import { slugifyURL } from '@/components/util';
import ClassNames from 'classnames';

const ViewAll = props => {
  const { text, url, root_class, root_style, preventDefault, target } = props;
  return (
    <a
      href={url ? slugifyURL(url) : preventDefault ? '#' : '/'}
      target={target ?? '_self'}
      onClick={
        preventDefault
          ? e => e.preventDefault()
          : e => {
              e.stopPropagation();
            }
      }
      data-cy={'view-all-link'}
      style={root_style}
      className={ClassNames(['view-all-link', root_class])}>
      {text}
    </a>
  );
};

export default ViewAll;
