import Skeleton from 'react-loading-skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ClassNames from 'classnames';
import Variables from 'styles/variables.module.scss';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    textAlign: 'center',
    borderRadius: '4px',
    border: '1px solid #D6D6FF',
    boxShadow: '4px 4px 7px #0056b217',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      boxShadow: '0 10px 35px rgb(0 86 179 / 20%)',
    },
  },
  pkTestimonialGrid: {
    textAlign: 'center',
    position: 'relative',
  },
  pkTestimonialGridPos: {
    position: 'relative',
    textAlign: 'center',
    bottom: '-10%',
    left: '0%',
    '& img': {
      margin: '0 auto',
      backgroundColor: '#ffffff',
      borderRadius: '50%',
      height: '60px !important',
    },
  },
  heading: {
    textAlign: 'left',
    overflow: 'hidden',
    color: Variables.darkColor,
    whiteSpace: 'break-spaces',
  },
  borderRight: {
    borderRight: '5px solid #0056B2',
    height: '65%',
    marginTop: '2px',
    marginRight: '10px',
  },
}));

export default function FallbackCardTestimonial() {
  const classes = useStyles();

  return (
    <>
      <Grid
        data-cy={'testimonial-card'}
        className={ClassNames([classes.root, 'testimonial-card'])}>
        <Grid container justifyContent="flex-start" spacing={4}>
          <Grid item className={classes.heading}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={1} style={{ textAlign: 'right' }}>
                <div>
                  <img
                    style={{ marginRight: '5px' }}
                    width="30px"
                    height="30px"
                    src="/static/images/quote.svg"
                    alt="quote"
                  />
                </div>
                <div className={classes.borderRight}></div>
              </Grid>
              <Grid item xs={11}>
                {[...Array(3)].map((e, i) => {
                  return (
                    <Skeleton
                      key={i}
                      animation="wave"
                      variant="rect"
                      width={250}
                      height={10}
                      style={{
                        margin: '10px 0',
                      }}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: '10px', paddingLeft: '25px' }}
          container
          justifyContent="flex-start"
          spacing={4}>
          <Grid item xs={4}>
            <div className={classes.pkTestimonialGridPos}>
              <Skeleton
                animation="wave"
                variant="circle"
                width={50}
                height={50}
              />
            </div>
          </Grid>
          <Grid xs={8} item style={{ textAlign: 'left' }}>
            <Skeleton animation="wave" variant="rect" width={200} height={10} />
            <Skeleton animation="wave" variant="rect" width={200} height={10} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
