import React, { useState, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import {
  getRecommendedProjects,
  nextRecommendedProjects,
  addWishlist,
} from '../../../redux/actions/main';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CommonHeaderText from '../../packages/common-header-text';
import ViewAll from '../../Desktop/Common/ViewAll';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  // capitalizeFirstLetter,
  slugify,
  gotoPropertyDetailsPage,
  getSearchPageURL,
  searchURLType,
} from '../../util';
import ClassNames from 'classnames';
// import MobHorizontalScroll from '../../../components/packages/mob-horizontal-scroll';
import MobHorizontalScrollNew from '@/components/packages/mob-horizontal-scroll-new';
import _ from 'lodash';
import useAccessToken from '../../packages/use-access-token';
import useUserInfo from '../../packages/use-user-info';
import MobBestSellingProjectCard from '@/components/packages/mob-best-selling-project-card';
import Variables from 'styles/variables.module.scss';
import classes from './mobRecommendProject.module.scss';

const highlightedWord = word => {
  return (
    <span
      style={{
        color: Variables.darkorangeColor,
        // fontSize: '18px',
        // fontWeight: 700,
      }}>
      {word}
    </span>
  );
};
// All items component
// Important! add unique key
export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  module_type,
  sliderbtnClass,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
  toggleWishlist
) =>
  list &&
  list.length > 0 &&
  // list
  _.uniqBy(list, 'pId')
    // .sort((a, b) => (a.weRecommendCount < b.weRecommendCount ? 1 : -1))
    .filter(project => {
      if (project) {
        return true;
      }
    })
    .map((el, index) => {
      return (
        <MenuItem
          text={el}
          key={index}
          loading={loading}
          open_modal={open_modal}
          propertyDetailsClick={propertyDetailsClick}
          propertyDetailsGallery={propertyDetailsGallery}
          module_type={module_type}
          sliderbtnClass={sliderbtnClass}
          cachedProject={cachedProject}
          wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
          addwishlisttrigger={addwishlisttrigger}
          setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
          deletecachedpropertytrigger={deletecachedpropertytrigger}
          toggleWishlist={toggleWishlist}
        />
      );
    });

const MenuItem = ({
  text,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
  toggleWishlist,
  // sliderbtnClass,
}) => {
  const {
    pId,
    projectImage,
    buildingName,
    projectName,
    weRecommendCount,
    developerName,
    maxPrice,
    minPrice,
    liveabilityIndexCount,
    localityName,
    cityName,
    bestForInvestmentCount,
    streetView,
    videoLink,
    minCarpetArea,
    maxCarpetArea,
    configurationTypeCount,
    wishlists,
    furnishingStatus,
    propetyBuyOrRent,
  } = text;
  const bhk_type_label = Object.keys(configurationTypeCount);
  const bhk_types = bhk_type_label
    .map(data => {
      return configurationTypeCount[data].toString() + ' ' + data;
    })
    .toString();
  const onScheduleTourClick = (propertyid, property_name) => {
    if (open_modal) open_modal(propertyid, property_name, developerName);
  };
  return (
    <MobBestSellingProjectCard
      maxCarpetArea={maxCarpetArea}
      isResale={propetyBuyOrRent === 'resale'}
      setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
      deletecachedpropertytrigger={deletecachedpropertytrigger}
      addwishlisttrigger={addwishlisttrigger}
      wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
      cachedProject={cachedProject}
      projectName={projectName}
      livability_index={liveabilityIndexCount}
      investment_index={bestForInvestmentCount}
      locationwithoutCityName={localityName}
      maxPrice={maxPrice}
      minPrice={minPrice}
      priceToshow={
        maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
      }
      toggleWishlist={toggleWishlist}
      developer_name={`By ` + developerName}
      bhk_types={bhk_types}
      p_we_recommend_count={weRecommendCount}
      property_id={pId}
      propertyDetailsClick={propertyDetailsClick}
      location={localityName}
      propertyDetailsGallery={propertyDetailsGallery}
      property_name={module_type == 'rent' ? buildingName : projectName}
      // scheduleTourClick={open_modal}
      scheduleTourClick={onScheduleTourClick}
      title={
        <CommonHeaderText
          variant="h4"
          text={projectName}
        />
      }
      subheader={
        <CommonHeaderText
          variant="h5"
          text={`by ${developerName}`}
          style={{
            fontSize: '13px',
            color: '#6F6F6F',
            fontWeight: '400',
          }}
        />
      }
      star_rating={weRecommendCount}
      sqft={
        <CommonHeaderText
          variant="h5"
          text={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
        />
      }
      details={
        <CommonHeaderText
          variant="h5"
          text={localityName + ', ' + cityName}
        />
      }
      bhk={
        bhk_types && (
          <CommonHeaderText
            variant="h5"
            text={bhk_types}
          />
        )
      }
      livabilityRating={`${liveabilityIndexCount} / 10`}
      investmentRating={`${bestForInvestmentCount} / 10`}
      priceTag={
        minPrice && maxPrice && parseInt(minPrice) && parseInt(maxPrice) ? (
          <CommonHeaderText
            variant="h4"
            text={
              maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
            }
          />
        ) : (
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              open_modal(pId, projectName, false, true);
            }}>
            <CommonHeaderText
              text={'Price On Request'}
              variant="h4"
              style={{
                cursor: 'pointer',
                color: Variables.darkColor,
                fontSize: '14px',
                fontWeight: '700',
              }}
            />
          </div>
        )
      }
      Images={projectImage}
      ImageWidth="90px"
      ImageHeight="125px"
      street_url={streetView}
      video_url={videoLink}
      module_type={slugify(module_type)}
      cityName={slugify(cityName)}
      wishlists={wishlists}
      squarFit={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
      minCarpetArea={minCarpetArea}
      furnishingStatus={furnishingStatus}
    />
  );
};

const RecommendProjectSection = props => {
  let { module_type, sliderbtnClass } = props;
  const {
    forwardedRef,
    open_modal,
    propertyDetailsGallery,
    city,
    cachedProject,
    callWishlistApi,
    wishlistPropertyDeletedCall,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
  } = props;

  const { projects, loading } = props.apiData;
  module_type = module_type || 'buy';
  const [access_token] = useAccessToken();
  const [user] = useUserInfo();
  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      callBack: callWishlistApi,
    });
    wishlistPropertyDeletedCall();
  };
  const propertyDetailsClick = (property_id, property_name, location) => {
    // e.stopPropagation()
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
      localityName: location,
    });
  };

  const firstmenu = Menu(
    projects,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    module_type,
    sliderbtnClass,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    toggleWishlist
  );

  const [limit] = useState(6);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      let userId = user?.id;
      props.getRecommendedProjects({
        city_id: city?.id,
        skip: 0,
        limit: limit,
        module_type: module_type,
        section: 'recommend',
        user_id: userId,
      });
    }
    return () => {
      mounted = true;
    };
  }, [city?.id, module_type, limit]);
  if (projects && projects.length == 0) return null;
  return (
    <section
      className="main-section"
      style={{ backgroundColor: '#FAFAFA' }}
      ref={forwardedRef}>
      <div className="container">
        <Grid
          justifyContent="space-between"
          container
          alignItems="flex-start"
          className="heading-section">
          <Grid item className={classes.headingRecommend}>
            {!projects || (projects.length === 0 && loading) ? (
              <Skeleton
                animation="wave"
                variant="rect"
                width={200}
                height={10}
              />
            ) : (
              <CommonHeaderText
                // text={
                //   'Best Selling Properties in ' +
                //   capitalizeFirstLetter(city?.name)
                // }
                variant="h3"
                text={<>{highlightedWord('Top Selling')} Projects </>}
                size="18px"
                color="#000"
              />
            )}
            {!projects || (projects.length === 0 && loading) ? (
              <Skeleton
                animation="wave"
                variant="rect"
                width={200}
                height={10}
              />
            ) : (
              <CommonHeaderText
                text="Projects in High Demand"
                size="13px"
                color="#000"
              />
            )}
          </Grid>
          <Grid item>
            <ViewAll
              text="View All"
              url={getSearchPageURL(
                searchURLType.recommendedProjects,
                module_type,
                city?.name
              )}
              // url={`/${module_type}/deal-of-the-week-in-${slugify(
              //   city?.name
              // )}`}
            />
          </Grid>
        </Grid>
      </div>
      <div
        className={ClassNames(['container', 'card-section'])}
        style={{
          position: 'relative',
        }}>
        {!projects || (projects.length === 0 && loading) ? (
          <Skeleton animation="wave" variant="rect" width={1} height={1} />
        ) : (
          <MobHorizontalScrollNew data={firstmenu} />
        )}
      </div>
    </section>
  );
};

const MobRecommendProject = handleViewport(RecommendProjectSection, {
  rootMargin: '-1.0px',
});

const mapStateToProps = state => ({
  apiData: state.recommendedReducer,
});

const mapDispatchToProps = {
  getRecommendedProjects,
  nextRecommendedProjects,
  addWishlist,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobRecommendProject);
