import React, { useRef, useState } from 'react';
import classNames from 'classnames';
// import { makeStyles } from '@material-ui/core';
import ClassNames from 'classnames';
import HBImage from '@/components/packages/hbimage';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import classes from './mob-horizontal-scroll.module.scss';

const MobHorizontalScroll = ({
  data,
  onSelect,
  itemClass,
  innerWrapperClass,
  arrowLeft,
  arrowRight,
  value,
  arrowLeft1,
  arrowRight1,
  innerWrapperStyle,
  rightArrowStyle,
  leftArrowStyle,
}) => {
  const [firstItemRef, setFirstItemRef] = useState(null);
  const [, setActiveRef] = useState(null);
  // const classes = useStyles();
  const wrapperRef = useRef(null);

  function onRightArrowClick() {
    wrapperRef.current?.scroll({
      top: 0,
      left: wrapperRef.current?.scrollLeft + firstItemRef.clientWidth,
      behavior: 'smooth',
    });
  }

  function onLeftArrowClick() {
    wrapperRef.current.scroll({
      top: 0,
      left: wrapperRef.current?.scrollLeft + firstItemRef.clientWidth * -1,
      behavior: 'smooth',
    });
  }

  return (
    <div className={classes.positionRelative}>
      <div
        ref={wrapperRef}
        style={{ ...innerWrapperStyle }}
        className={ClassNames([innerWrapperClass, classes.wrapper])}>
        {data &&
          data.map((item, i) => (
            <div
              ref={ref => {
                if (i === 0 && ref) setFirstItemRef(ref);
                else setActiveRef(ref);
              }}
              onClick={() => {
                if (onSelect) {
                  onSelect(i);
                }
              }}
              key={i}
              className={classNames([itemClass, classes.item])}>
              {item}
            </div>
          ))}
        {arrowRight && (
          <div
            onClick={onRightArrowClick}
            className="nextico-our-culture"
            style={rightArrowStyle}>
            <>
              {value == 'ratingReview' ? (
                <HBImage
                  width="25px"
                  height="25px"
                  src="/static/images/right-arrow-test.png"
                  alt="right-arrow-test"
                />
              ) : (
                <HBImage
                  width="20px"
                  height="20px"
                  src="/static/images/arrow-right1.png"
                  alt="right-arrow-test"
                />
              )}
            </>
          </div>
        )}
        {arrowLeft && (
          <div
            onClick={onLeftArrowClick}
            className="previco-our-culture"
            style={leftArrowStyle}>
            {value == 'ratingReview' ? (
              <HBImage
                width="25px"
                height="25px"
                src="/static/images/left-arrow-test.png"
                alt="left-arrow-test"
              />
            ) : (
              <HBImage
                width="20px"
                height="20px"
                src="/static/images/arrow-left1.png"
                alt="left-arrow-test"
              />
            )}
          </div>
        )}

        {arrowRight1 && (
          <div onClick={onRightArrowClick} className="shortpost-next">
            <ChevronRightIcon></ChevronRightIcon>
          </div>
        )}
        {arrowLeft1 && (
          <div onClick={onLeftArrowClick} className="shortpost-prev">
            <ChevronLeftIcon></ChevronLeftIcon>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobHorizontalScroll;
