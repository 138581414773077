import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

function PropertyCardStarRatingMaterial(props) {
  const { emptyIcon, rating, root_class, precision } = props;

  return (
    <>
      <Rating
        className={root_class}
        // name="customized-readonly"
        value={parseFloat(rating)}
        readOnly
        precision={precision}
        emptyIcon={<StarBorderIcon fontSize="inherit" className={emptyIcon} />}
      />
    </>
  );
}

export default PropertyCardStarRatingMaterial;
