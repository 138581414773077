import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ClassNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CommonCardContainer from '@/components/packages/common-card-container';
// import PropertyCardStarRatingMaterial from '@/components/packages/property-card-star-rating-material';
import MobPropertyCardStarRatingMaterial from '../mob-property-card-star-rating-material';
import { Tooltip, withStyles } from '@material-ui/core';
import SessionStorage from '@/components/Desktop/Common/Wishlist/SessionStorage';
import _ from 'lodash';
import useAccessToken from '../use-access-token';
import ImagesSlider from '../images-slider';
import Variables from 'styles/variables.module.scss';
import { getPropertyDetailsURL } from '@/components/util';
// import ImagesSlider from '../images-slider/imageSliderBackup';

const useStyles = makeStyles(() => ({
  root: {
    border: '0',
    paddingBottom: '0px',
    marginRight: '12px',
    boxShadow: 'none !important',
    background: 'transparent',
    borderRadius: '6px',
    '& .MuiCardContent-root': {
      padding: 0,
      '& img': {
        orderRadius: '6px',
        // overflow: 'hidden',
        // marginRight: '3px',
      },
    },
  },
  cardTop: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px',
    '& button': {
      color: '#fff!important',
      '& img': {
        width: '22px!important',
        height: '22px!important',
        backgroundColor: 'rgba(0 , 0, 0, 0.3)!important',
        borderRadius: '50%!important',
        padding: '4px',
      },
    },
    '& img': {
      borderRadius: '6px',
      marginTop: '5px',
      marginRight: '0px',
      overflow: 'hidden',
    },
  },
  cardTopIcons: {
    zIndex: 2,
    position: 'absolute',
    top: '0px',
    left: '0px',
    padding: '16px 18px',
    alignItems: 'center',
    backgroundImage:
      'linear-gradient(to top , rgb(0 0 0 / 0%), rgb(17 31 35 / 85%))',
  },
  cardTopDetails: {
    paddingRight: '10px',
  },
  cardBody: {
    padding: '10px 15px',
    // border: '1px solid #D2DEEB',
    // borderRadius: '7px',
    paddingBottom: '0px',
  },
  cardDetails: {
    width: '100%',
    minHeight: '70px',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-4px',
  },
  media: {
    height: '50px',
    paddingTop: '56.25%', // 16:9
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: Variables.darkColor,

    '& h5': {
      fontSize: '13px',
      color: '#6F6F6F',
      fontWeight: '400',
    },
  },

  projectText: {
    '& h4': {
      color: Variables.darkColor,
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '22px',
      marginBottom: '5px',
    },
    '& h5': {
      fontSize: '13px',
      color: '#6F6F6F',
      fontWeight: '400',
    },
  },
  cardIndex: {
    fontSize: '12px',
    // backgroundColor: "#EE6A2A",
    borderRadius: '15px',
    color: '#EE6A2A',
    textAlign: 'center',
    display: 'flex',
    // padding: "0 4px",
    // lineHeight: 2,
    fontWeight: 'bold',
  },
  cardIndexMain: {
    paddingTop: '5px',
    color: '#000',
    display: 'flex',
    fontSize: '12px',
    fontWeight: '300',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        padding: '0 2px 0 0',
      },
    },
  },

  newProjectCard: {
    position: 'relative',
    marginBottom: '0px',
    backgroundColor: 'transparent',
  },
  ProjectCard: {
    boxShadow: 'none',
    // marginBottom: '11px',
    padding: '10px 15px',
    borderRadius: '4px',
    marginTop: '-19%',
    zIndex: '9',
    position: 'relative',
    width: '100%',
    backgroundColor: 'transparent',
  },

  ProjectCardBox: {
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow: 'none',
    // marginBottom: '11px',
    width: '100%',
    borderRadius: '7px',
    border: '1px solid #D2DEEB',
  },
  titleTag: {
    marginTop: '-30px',
    marginBottom: '10px',
  },
  iconBgColor: {
    width: '31px',
    height: '28px',
    padding: '3px 0px 0px 0px',
    backgroundColor: '#17171755',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconFilled: {
    color: '#FFFFFF',
    marginTop: '0px',
    fontSize: '16px',
  },
  mobDealWeek: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '2px',
    marginTop: '7px',
    marginBottom: '8px',
  },
  mobDealWeekImgOffer: {
    width: '35%',
    background: '#1A4D72',
    padding: '5px',
    height: '26px',
  },
  mobDealWeekImg: {
    width: '35%',
    background: '#764736',
    padding: '5px',
    height: '26px',
  },
  mobDealWeekcontentOffer: {
    width: '60%',
    padding: '6px',
    background: 'linear-gradient(180deg, #6AB4EB,#1A4D72)',
    textAlign: 'center',
    fontFamily: 'open sans',
    fontWeight: '700',
    color: '#fff',
    fontSize: '10px',
    lineHeight: '14px',
    height: '26px',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
  },
  mobDealWeekcontent: {
    width: '60%',
    padding: '6px',
    background: 'linear-gradient(180deg, #FC9B67,#764736)',
    textAlign: 'center',
    fontFamily: 'open sans',
    fontWeight: '700',
    color: '#fff',
    fontSize: '10px',
    lineHeight: '14px',
    height: '26px',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
  },
  flatInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '7px',

    '& h4': {
      fontSize: '15px',
      color: Variables.darkColor,
      fontWeight: '700',
      // marginTop: '-6px',
      paddingLeft: '6px',
    },
    '& h5': {
      fontSize: '13px',
      color: Variables.darkColor,
      fontWeight: '500',
      paddingLeft: '6px',
      paddingTop: '2px',
      marginTop:'0px',
      // paddingLeft :'6px'

    },
  },
  
 rupeeIcon: {
  objectPosition: '-29px 12px',
  objectFit: 'cover',
  width: '14px',
  height: '28px',
  marginTop: '-11px'},

  flatInfo1: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '7px',

    '& h4': {
      fontSize: '15px',
      color: Variables.darkColor,
      fontWeight: '700',
      // marginTop: '-6px',
      paddingLeft: '6px',
    },
    '& h5': {
      fontSize: '13px',
      color: Variables.darkColor,
      fontWeight: '500',
      paddingLeft: '6px',
      paddingTop: '2px',
      marginTop:'-10px',
      marginLeft:'-2px',
      // paddingLeft :'6px'

    },
  },

  flatsubhead: {
    '& h4': {
      fontSize: '15px',
      color: Variables.darkColor,
      fontWeight: '700',
      marginTop: '-6px',
    },

    '& h5': {
      fontSize: '13px',
      color: Variables.darkColor,
      fontWeight: '500',
    },
  },
  phoneIcon: {
    background: '#0056B2',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '7px',
    position: 'absolute',
    right: '30px',
    bottom: '28px',
    '& img': {
      objectPosition: '-437px 5px',
      objectFit: 'cover',
      width: '21px',
      height: '30px',
    },
  },
}));

const MobNewProjectCard = props => {
  const {
    title,
    details,
    bhk,
    priceTag,
    Images,
    street_url,
    video_url,
    sqft,
    star_rating,
    property_id,
    property_name,
    // propertyDetailsClick,
    scheduleTourClick,
    propertyDetailsGallery,
    module_type,
    cityName,
    wishlists,
    offer,
    dealOffer,
    location,
    livability_index,
    investment_index,
    p_we_recommend_count,
    priceToshow,
    toggleWishlist,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    projectName,
    bhk_types,
    minPrice,
    maxPrice,
    developer_name,
    squarFit,
    minCarpetArea,
    furnishingStatus,
    maxCarpetArea,
    isResale,
    cardIndex
  } = props;
  const classes = useStyles();
  const propertyURL = getPropertyDetailsURL({
    module_type,
    city_name: cityName,
    property_name,
    property_id,
    location,
    bhkTypes: '',
    isResale: false,
  });

  const [dealPopup, setdealPopup] = useState(false);
  const [offerPopup, setOfferPopup] = useState(false);
  const dealTooltipRef = useRef();
  const [access_token] = useAccessToken();
  const [wishlist_flag, setWishlistFlag] = useState(wishlists);
  const MoreDetailTooltip = withStyles({
    tooltip: {
      color: Variables.darkColor,
      backgroundColor: '#fff',
      border: '1px solid #D3E0ED',
      marginBottom: '10px',
      width: '150px',
      fontSize: '13px!important',
      padding: '10px !important',
      fontWeight: '700!important',
      fontFamily: 'Open Sans, sans-serif !important',
      marginTop: '0!important',
    },
    arrow: {
      '&:before': {
        border: '1px solid #D3E0ED',
      },
      color: '#fff',
    },
  })(Tooltip);

  const imgSlider = useRef();
  React.useEffect(() => {
    if (imgSlider.current) {
      let btnElements = imgSlider.current.querySelectorAll('button');
      btnElements.forEach(element => element.classList.add('cardNotClicked'));
    }
  }, []);
  const setWishlistFlagFunc = _.debounce(propertyid => {
    toggleWishlist(propertyid);
    setWishlistFlag(!wishlist_flag);
  });
  const saveDatainSession = (
    investment_index_save,
    cityNameSave,
    property_id_save,
    projectNameSave,
    bhk_types_save,
    developer_name_save,
    livability_index_save,
    location_save,
    module_type_save,
    images,
    priceToshowSave,
    street_url_save,
    video_url_save,
    p_we_recommend_count_save,
    sqft_save,
    minPrice_save,
    maxPrice_save,
    minCarpetArea_save,
    furnishingStatusSave
  ) => {
    SessionStorage({
      investment_index_save,
      cityNameSave,
      property_id_save,
      projectNameSave,
      buildingName: '',
      bhk_types_save,
      furnishStatus: '',
      developer_name_save,
      livability_index_save,
      location_save,
      module_type_save,
      images,
      priceToshowSave,
      street_url_save,
      video_url_save,
      p_we_recommend_count_save,
      sqft_save,
      minPrice_save,
      maxPrice_save,
      minCarpetArea_save,
      furnishingStatusSave,
      maxCarpetArea,
      isResale,
    });
    setWishlistFlag(!wishlist_flag);
    setDeleteCachedPropertyTrigger(!deletecachedpropertytrigger);
  };
  useEffect(() => {
    if (access_token && access_token != '') {
      setWishlistFlag(wishlists);
    } else {
      setWishlistFlag(0);
      cachedProject &&
        cachedProject.map(data => {
          const { project } = data;
          project &&
            project.map(dataWishlist => {
              const { propertyId } = dataWishlist;

              if (propertyId == property_id) {
                setWishlistFlag(1);
              }
            });
        });
    }

    return;
  }, [
    wishlists,
    cachedProject,
    access_token,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    property_id,
  ]);
  const croppedImage = Images.map(imageURL => `${imageURL}`);

  return (
    <CommonCardContainer url={propertyURL} target="_self">
      <Card
        className={ClassNames([classes.root, 'mob-property-card'])}
        // onClick={e => e.stopPropagation()}
      >
        <CardContent>
          <div className={classes.newProjectCard}>
            <div
              ref={imgSlider}
              className={ClassNames([classes.cardTop, 'cardNotClicked'])}>
              <ImagesSlider
                images={croppedImage}
                projectName={property_name}
                sliderbtnClass={'cardNotClicked'}
                width={300}
                height={170}
                isFirstSearchCard={cardIndex === 0}
              />
            </div>
            <Grid
              className={classes.cardTopIcons}
              container
              justifyContent="space-between">
              <Grid item xs={6} style={{
                    marginTop: '-18px',
                    display: 'flex',
                  }}>
                {/* <div
                  style={{
                    marginTop: '-18px',
                    display: 'flex',
                  }}> */}
                  <MobPropertyCardStarRatingMaterial
                    rating={star_rating}
                    precision={0.5}
                    root_class={classes.iconFilled}
                    emptyIcon={classes.iconBorder}
                  />
                {/* </div> */}
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  {street_url && street_url != '' && (
                    <Grid
                      style={{
                        cursor: 'pointer',
                        marginTop: '-1px',
                      }}
                      item
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'STREET_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Street View"
                        style={{
                          objectPosition: '-344px 0px',
                          objectFit: 'cover',
                          width: '30px',
                          height: '38px',
                        }}
                      />
                    </Grid>
                  )}
                  {video_url && video_url != '' && (
                    <Grid
                      style={{
                        cursor: 'pointer',
                        marginTop: '-1px',
                      }}
                      item
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'VIDEO_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Video"
                        style={{
                          objectPosition: '-316px 0px',
                          objectFit: 'cover',
                          width: '20px',
                          height: '38px',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    style={{
                      cursor: 'pointer',
                      marginTop: '-1px',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      access_token
                        ? setWishlistFlagFunc(property_id)
                        : saveDatainSession(
                            investment_index,
                            cityName,
                            property_id,
                            projectName,
                            bhk_types,
                            developer_name,
                            livability_index,
                            location,
                            module_type,
                            Images,
                            priceToshow,
                            street_url,
                            video_url,
                            p_we_recommend_count,
                            squarFit,
                            minPrice,
                            maxPrice,
                            minCarpetArea,
                            furnishingStatus
                          );
                    }}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="Favourite"
                      style={{
                        objectPosition: wishlist_flag
                          ? '-281px 0px'
                          : '-252px 0px',
                        objectFit: 'cover',
                        width: '26px',
                        height: '38px',
                        position: 'relative',
                        top: '0px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className={classes.ProjectCard}>
              <Grid className={classes.ProjectCardBox}>
                <div className={classes.cardBody}>
                  <div
                    className={classes.phoneIcon}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      scheduleTourClick(property_id, property_name);
                    }}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="metro-location"
                      // style={{
                      //   objectPosition: '-437px 5px',
                      //   objectFit: 'cover',
                      //   width: '21px',
                      //   height: '30px',
                      // }}
                    />
                  </div>
                  <Grid container>
                    <>
                      <Grid xs={12} item className={classes.projectText}>
                        {title}
                        {details}
                      </Grid>
                    </>
                    {/* <Grid xs={12} item className={classes.location}>
                    
                    </Grid> */}

                    {dealOffer && (
                      <MoreDetailTooltip
                        title={dealOffer}
                        open={dealPopup}
                        arrow>
                        <Grid
                          xs={3}
                          item
                          className={classes.mobDealWeek}
                          style={{ marginRight: '14px' }}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setdealPopup(!dealPopup);
                            setTimeout(() => {
                              setdealPopup(false);
                            }, 2000);
                          }}>
                          {/* <div className={classes.mobDealWeek}> */}
                          <img
                            src="/static/images/home-icons.svg"
                            alt="deal"
                            style={{
                              objectPosition: '-700px 0px',
                              objectFit: 'cover',
                              width: '68px',
                              height: '30px',
                            }}
                          />
                          {/* </div> */}
                        </Grid>
                      </MoreDetailTooltip>
                    )}
                    {offer && (
                      <MoreDetailTooltip
                        ref={dealTooltipRef}
                        title={offer}
                        open={offerPopup}
                        arrow>
                        <Grid
                          xs={3}
                          style={{ marginBottom: '3px' }}
                          item
                          className={classes.mobDealWeek}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOfferPopup(!offerPopup);
                            setTimeout(() => {
                              setOfferPopup(false);
                            }, 2000);
                          }}>
                          {/* <div className={classes.mobDealWeek}> */}
                          <img
                            src="/static/images/home-icons.svg"
                            alt="offer"
                            style={{
                              objectPosition: '-631px 0px',
                              objectFit: 'cover',
                              width: '70px',
                              height: '30px',
                            }}
                          />
                          {/* </div> */}
                        </Grid>
                      </MoreDetailTooltip>
                    )}
                    <div className={classes.cardDetails}>
                      {priceTag && (
                        <Grid container className={classes.flatInfo}>
                          <img
                            src="/static/images/home-icons.svg"
                            alt="rupee"
                            className={classes.rupeeIcon}
                            // style={{
                            //   objectPosition: '-29px 12px',
                            //   objectFit: 'cover',
                            //   width: '14px',
                            //   height: '28px',
                            //   marginTop: '-11px',
                            // }}
                          />
                          {priceTag}
                        </Grid>
                      )}
                      {bhk && (
                        <Grid container className={classes.flatInfo}>
                          {/* <Grid item xs={1}> */}
                          <img
                            src="/static/images/home-icons.svg"
                            alt="bhk"
                            style={{
                              objectPosition: '0px 0px',
                              objectFit: 'cover',
                              width: '15px',
                              height: '25px',
                              marginBottom: '-5px',
                            }}
                          />
                          {/* </Grid> */}
                          {/* <Grid item xs={11} className={classes.flatsubhead}> */}
                          {bhk}
                          {/* </Grid> */}
                        </Grid>
                      )}
                      {sqft && (
                        <Grid container className={classes.flatInfo1}>
                          <img
                            src="/static/images/home-icons.svg"
                            alt="carpet-area"
                            style={{
                              objectPosition: '-48px 0px',
                              objectFit: 'cover',
                              width: '18px',
                              height: '28px',
                            }}
                          />
                          {/* <span
                            style={{ marginTop: '-10px', marginLeft: '-2px' }}> */}
                            {sqft}
                          {/* </span> */}
                        </Grid>
                      )}
                    </div>
                  </Grid>

                  <Grid
                    style={{ marginBottom: '10px' }}
                    container
                    justifyContent="space-between">
                    <Grid item></Grid>
                  </Grid>
                </div>
              </Grid>
            </div>
          </div>
        </CardContent>
      </Card>
    </CommonCardContainer>
  );
};

export default MobNewProjectCard;
