/* ########## COMPONENT IMPORTS ########## */
import ImagesSlider from '@/components/packages/images-slider';
import SessionStorage from '@/components/Desktop/Common/Wishlist/SessionStorage';
import useAccessToken from '@/components/packages/use-access-token';

/* ########## MATERIAL UI IMPORTS ########## */
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import React, { useEffect, useState } from 'react';
import ClassNames from 'classnames';
import _ from 'lodash';
import CommonCardContainer from '../common-card-container';
import { getPropertyDetailsURLForRent } from '@/components/util';

const useStyles = makeStyles({
  root: {
    // borderRadius: "15px",
    border: '1px soild #ccc',
    paddingBottom: '0px',
    marginRight: '15px',
    boxShadow: '4px 4px 7px #0056b217',
    borderRadius: '15px',
    '& .MuiCardContent-root': {
      padding: '14px',
      paddingBottom: '5px',
      '& img': {
        // borderRadius: "15px",
      },
    },
  },
  cardTop: {
    position: 'relative',
    '& img': {
      // borderRadius: "12px",
    },

    '& figure span': {
      borderRadius: '12px !Important',
    },
    // '&::after': {
    //   content: '""',
    //   position: 'absolute',
    //   top: 0,
    //   bottom: '5px',
    //   right: 0,
    //   left: 0,
    //   background:
    //     'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 21%, rgba(0,0,0,0) 37%)',
    //   display: 'block',
    //   borderRadius: '12px',
    //   zIndex: 1,
    // },
  },
  cardTopIcons: {
    zIndex: 2,
    position: 'absolute',
    top: '93px',
    left: '0px',
    padding: '0 10px',
  },
  cardTopDetails: {
    zIndex: 2,
    position: 'absolute',
    bottom: '-120px',
    right: '10px',
  },
  cardBody: {
    padding: '10px',
    paddingBottom: '0px',
  },
  media: {
    height: '50px',
    paddingTop: '56.25%', // 16:9
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  cardIndex: {
    fontSize: '12px',
    // backgroundColor: "#EE6A2A",
    borderRadius: '15px',
    color: '#EE6A2A',
    textAlign: 'center',
    display: 'flex',
    // padding: "0 4px",
    marginLeft: '6px',
    // lineHeight: 2,
    fontWeight: 'bold',
  },
  cardIndexMain: {
    color: 'rgba(23, 23, 23, 0.8)',
    display: 'flex',
    fontSize: '12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        padding: '0 2px 0 0',
      },
    },
  },
  iconBgColor: {
    width: '31px',
    height: '28px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  iconCall: {
    width: '31px',
    height: '28px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
});

const MobRecentlyPostCard = props => {
  const {
    cardIndex,
    title,
    cityName,
    module_type,
    details,
    priceTag,
    Images,
    street_url,
    video_url,
    sqft,
    property_id,
    property_name,
    furnishingStatus,
    buildingName,
    location,
    livability_index,
    investment_index,
    p_we_recommend_count,
    priceToshow,
    toggleWishlist,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    projectName,
    bhk_types,
    minPrice,
    maxPrice,
    developer_name,
    wishlists,
    minCarpetArea,
    maxCarpetArea,
    scheduleTourClick,
    isResale: resale,
  } = props;
  const classes = useStyles();
  const [access_token] = useAccessToken();
  const [wishlist_flag, setWishlistFlag] = useState(wishlists);

  const setWishlistFlagFunc = _.debounce(propertyid => {
    toggleWishlist(propertyid);
    setWishlistFlag(!wishlist_flag);
  });
  const saveDatainSession = (
    investment_index_save,
    cityNameSave,
    property_id_save,
    projectNameSave,
    buildingNameSave,
    bhk_types_save,
    furnishingStatusSave,
    developer_name_save,
    livability_index_save,
    location_save,
    module_type_save,
    images,
    priceToshowSave,
    street_url_save,
    video_url_save,
    p_we_recommend_count_save,
    sqft_save,
    minPriceSave,
    maxPriceSave,
    minCarpetAreaSave
  ) => {
    SessionStorage({
      investment_index_save,
      cityNameSave,
      property_id_save,
      projectNameSave,
      buildingNameSave,
      bhk_types_save,
      furnishStatus: furnishingStatusSave,
      developer_name_save,
      livability_index_save,
      location_save,
      module_type_save,
      images,
      priceToshowSave,
      street_url_save,
      video_url_save,
      p_we_recommend_count_save,
      sqft_save,
      minPriceSave,
      maxPriceSave,
      minCarpetAreaSave,
      maxCarpetArea,
      isResale: resale,
    });
    setWishlistFlag(!wishlist_flag);
    setDeleteCachedPropertyTrigger(!deletecachedpropertytrigger);
  };
  useEffect(() => {
    if (access_token && access_token != '') {
      setWishlistFlag(wishlists);
    } else {
      setWishlistFlag(0);
      cachedProject &&
        cachedProject.map(data => {
          const { project } = data;
          project &&
            project.map(dataCached => {
              const { propertyId } = dataCached;

              if (propertyId == property_id) {
                setWishlistFlag(1);
              }
            });
        });
    }
  }, [
    wishlists,
    cachedProject,
    access_token,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    property_id,
  ]);
  return (
    <>
      <CommonCardContainer
        url={getPropertyDetailsURLForRent(
          module_type,
          cityName,
          bhk_types,
          maxCarpetArea,
          buildingName,
          property_id,
          location
        )}
        target="_self">
        <Card className={ClassNames([classes.root, 'mob-rent-property-card'])}>
          <CardContent>
            <div className={classes.cardTop}>
              <ImagesSlider
                isFirstSearchCard={cardIndex === 0}
                images={Images}
                projectName={projectName}
                width={395}
                height={203}
              />

              <Grid item xs={12} className={classes.cardTopDetails}>
                <div
                  style={{
                    backgroundColor: '#0056B2',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '7px',
                  }}
                  className={classes.iconCall}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    scheduleTourClick(
                      property_id,
                      property_name,
                      developer_name
                    );
                  }}>
                  <img
                    src="/static/images/rental-collection/call-icon.svg"
                    height="18px"
                    width="18px"
                    title="Call"
                    alt="Call"
                  />
                </div>
              </Grid>
              <Grid
                className={classes.cardTopIcons}
                container
                justifyContent="space-between">
                <Grid item xs={10}>
                  <Grid container justifyContent="flex-end">
                    {module_type == 'buy' ? (
                      <Grid
                        item
                        className={classes.iconBgColor}
                        style={{ marginRight: '10px' }}>
                        {street_url && street_url != '' && (
                          <Grid
                            style={{
                              cursor: 'pointer',
                            }}
                            item>
                            <img
                              width="16px"
                              height="16px"
                              src="/static/images/street-view-360-square.svg"
                              alt="Street View"
                            />
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      ''
                    )}
                    <Grid item className={classes.iconBgColor}>
                      {video_url && video_url != '' && (
                        <Grid
                          style={{
                            cursor: 'pointer',
                          }}
                          item>
                          <img
                            width="16px"
                            height="16px"
                            src="/static/images/street-video-square-outline.svg"
                            alt="Video"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid container justifyContent="flex-end">
                    <Grid
                      item
                      className={classes.iconBgColor}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        access_token
                          ? setWishlistFlagFunc(property_id)
                          : saveDatainSession(
                              investment_index,
                              cityName,
                              property_id,
                              projectName,
                              buildingName,
                              bhk_types,
                              furnishingStatus,
                              developer_name,
                              livability_index,
                              location,
                              module_type,
                              Images,
                              priceToshow,
                              street_url,
                              video_url,
                              p_we_recommend_count,
                              sqft,
                              minPrice,
                              maxPrice,
                              minCarpetArea
                            );
                      }}>
                      <img
                        width="24px"
                        height="23px"
                        src={`/static/images/${
                          wishlist_flag
                            ? 'ionic-ios-heart.svg'
                            : 'ionic-ios-heart-empty.svg'
                        }`}
                        alt="Favourite"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className={classes.cardBody}>
              <Grid container justifyContent="space-between">
                <Grid xs={12} item>
                  {title}
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  style={{ marginTop: '-8px' }}>
                  <Grid item className={classes.location}>
                    <img
                      style={{ margin: '0 2px 0 0' }}
                      src="/static/images/metro-location.svg"
                      width="12px"
                      height="12px"
                      alt="metro-location"
                    />
                    {details}
                  </Grid>
                </Grid>

                <Grid xs={12} item>
                  <Grid container justifyContent="space-between">
                    <Grid xs={12} item style={{ paddingTop: '5px' }}>
                      {priceTag}
                    </Grid>

                    <Grid xs={6} item style={{ paddingTop: '5px' }}>
                      <Grid container>
                        <Grid item xs={2}>
                          <img
                            style={{ margin: '2px 5px 0 0' }}
                            src="/static/images/bhk.svg"
                            width="14px"
                            height="14px"
                            alt="bhk"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{
                            fontSize: '13px',
                            fontWeight: '400',
                            color: '#171717',
                          }}>
                          {furnishingStatus}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} item style={{ paddingTop: '5px' }}>
                  <Grid container>
                    <Grid item xs={1}>
                      <img
                        style={{ margin: '2px 5px 0 0' }}
                        src="/static/images/carpet-area.svg"
                        width="14px"
                        height="14px"
                        alt="carpet-area"
                      />
                    </Grid>
                    <Grid item xs={10}>
                      {sqft}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                style={{ marginBottom: '10px' }}
                container
                justifyContent="space-between">
                <Grid item></Grid>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </CommonCardContainer>
    </>
  );
};

export default MobRecentlyPostCard;
