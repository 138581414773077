import React, { useRef, useState, useEffect } from 'react';
import handleViewport from 'react-in-viewport';
import {
  getLivabilityProjects,
  updateLivabilityProjects,
  nextLivabilityProjects,
  addWishlist,
} from '../../../redux/actions/main';
import FallbackCard from '../Common/FallbackCard';
import { connect } from 'react-redux';
import ViewAll from '../Common/ViewAll';
import { Grid } from '@material-ui/core';
import CommonHeaderText from '../../packages/common-header-text';
// import { makeStyles } from '@material-ui/core/styles';
import HorizontalScrollButtons from '../Common/HorizontalScrollButtons';
// import { withStyles } from '@material-ui/styles';
import HorizontalScroll from '../../packages/horizontal-scroll';
import {
  capitalizeFirstLetter,
  slugify,
  gotoPropertyDetailsPage,
  getSearchPageURL,
  searchURLType,
} from '../../util';
import _ from 'lodash';
import useAccessToken from '../../packages/use-access-token';
import useUserInfo from '../../packages/use-user-info';
import PropertyCard from '../../packages/property-card';
import Variables from 'styles/variables.module.scss';
import classes from './livability.module.scss';
// import Tooltip from '@mui/material/Tooltip';

// All items component
// Important! add unique key
// const MoreDetailTooltip = withStyles({
//   tooltip: {
//     color: '#000',
//     backgroundColor: '#fff',
//     border: '1px solid #D3E0ED',
//     // marginBottom: '10px',
//     width: '150px',
//     fontSize: '12px!important',
//     padding: '10px !important',
//     fontWeight: '500!important',
//     fontFamily: 'Open Sans, sans-serif !important',
//   },
//   arrow: {
//     '&:before': {
//       border: '1px solid #D3E0ED',
//     },
//     color: '#fff',
//   },
// })(Tooltip);

export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger
) =>
  list &&
  list.length > 0 &&
  list
    .filter(project => {
      if (project) {
        return true;
      }
    })
    .map((el, index) => {
      return (
        <MenuItem
          text={el}
          key={index}
          cardIndex={index}
          loading={loading}
          open_modal={open_modal}
          propertyDetailsClick={propertyDetailsClick}
          propertyDetailsGallery={propertyDetailsGallery}
          toggleWishlist={toggleWishlist}
          openLoginModal={openLoginModal}
          module_type={module_type}
          cachedProject={cachedProject}
          wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
          addwishlisttrigger={addwishlisttrigger}
          setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
          deletecachedpropertytrigger={deletecachedpropertytrigger}
        />
      );
    });

const MenuItem = ({
  text,
  open_modal,
  cardIndex,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
}) => {
  const {
    pId,
    projectImage,
    projectName,
    weRecommendCount,
    developerName,
    maxPrice,
    minPrice,
    liveabilityIndexCount,
    localityName,
    cityName,
    bestForInvestmentCount,
    streetView,
    videoLink,
    minCarpetArea,
    maxCarpetArea,
    configurationTypeCount,
    wishlists,
    dealOffer,
    offer,
    furnishingStatus,
    propetyBuyOrRent,
  } = text;
  const bhk_type_label = Object.keys(configurationTypeCount);
  const bhk_types = bhk_type_label
    .map(data => {
      return configurationTypeCount[data].toString() + ' ' + data;
    })
    .toString();

  return (
    <>
      {/*  <Suspense> */}
      <PropertyCard
        cardIndex={cardIndex}
        isResale={propetyBuyOrRent === 'resale'}
        maxCarpetArea={maxCarpetArea}
        setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
        deletecachedpropertytrigger={deletecachedpropertytrigger}
        addwishlisttrigger={addwishlisttrigger}
        wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
        cachedProject={cachedProject}
        property_id={pId}
        projectName={projectName}
        property_name={projectName.replace(' ', '-').toLowerCase()}
        propertyDetailsClick={propertyDetailsClick}
        propertyDetailsGallery={propertyDetailsGallery}
        scheduleTourClick={open_modal}
        livability_index={liveabilityIndexCount}
        investment_index={bestForInvestmentCount}
        location={localityName + ', ' + cityName}
        locationwithoutCityName={localityName}
        sqft={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
        rootStyle={{
          fontFamily: 'Open Sans',
          marginRight: '0px',
          marginTop: '38px',
          position: 'relative',
          bottom: '28px',
        }}
        images={projectImage ? projectImage : []}
        p_project_name={
          <div
            style={{
              color: '#EE6A29',
              fontSize: '18px !important',
              fontWeight: 700,
              fontFamily: 'Open Sans',
            }}>
            {projectName.length > 31 ? (
              // <MoreDetailTooltip
              //   title={projectName}
              //   PopperProps={{
              //     popperOptions: {
              //       modifiers: {
              //         offset: {
              //           enabled: true,
              //           offset: '-80px, -15px',
              //         },
              //       },
              //     },
              //   }}
              //   arrow>
              //   <CommonHeaderText text={projectName} variant="h4" />
              // </MoreDetailTooltip>
            <></>
            ) : (
              <CommonHeaderText text={projectName} variant="h4" />
            )}
          </div>
        }
        developer_name={
          <CommonHeaderText text={`By ` + developerName} variant="h5" />
        }
        p_we_recommend_count={weRecommendCount}
        street_url={streetView}
        video_url={videoLink}
        price={
          minPrice && maxPrice && parseInt(minPrice) && parseInt(maxPrice) ? (
            <Grid container>
              <Grid item xs={1}>
                <img
                  src="/static/images/home-icons.svg"
                  alt="rupee"
                  style={{
                    objectPosition: '-29px 13px',
                    objectFit: 'cover',
                    width: '14px',
                    height: '28px',
                    marginTop: '-9px',
                  }}
                />
              </Grid>
              <Grid
                item
                className={classes.priceContent}
                style={{ marginLeft: '-3px' }}>
                <>
                  {maxPrice !== minPrice
                    ? minPrice + ' - ' + maxPrice
                    : minPrice}
                </>
              </Grid>
            </Grid>
          ) : (
            <div
              className="cardNotClicked"
              onClick={event => {
                event.preventDefault(); // Prevent the default click behavior
                open_modal(pId, projectName, false, true);
              }}>
              <span
                className="cardNotClicked"
                style={{
                  color: Variables.darkColor,
                  fontSize: '18px',
                  fontWeight: 700,
                  fontFamily: 'Open Sans',
                  marginBottom: '3px',
                }}>
                Price On Request
              </span>
            </div>
          )
        }
        bhk_types={bhk_types}
        wishlists={wishlists}
        toggleWishlist={toggleWishlist}
        openLoginModal={openLoginModal}
        module_type={slugify(module_type)}
        cityName={slugify(cityName)}
        offer={offer}
        dealOffer={dealOffer}
        dealAndOfferTooltip={true}
        priceToshow={
          maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
        }
        maxPrice={maxPrice}
        minPrice={minPrice}
        minCarpetArea={minCarpetArea}
        furnishingStatus={furnishingStatus}
      />
      {/* </Suspense> */}
    </>
  );
};

// const useStyles = makeStyles({
//   highlightWord: {
//     color: Variables.secondaryColor,
//     fontWeight: 'bold',
//   },
//   capitals: {
//     textTransform: 'uppercase',
//     // color:"#171717"
//   },
//   developerCardGrid: {
//     height: '100%',
//     maxHeight: '450px',
//   },
//   livabilityData: {
//     // marginTop: '-20px !important',
//   },

//   livabilityHeading: {
//     '& h1': {
//       letterSpacing: '-0.01562em !important',
//       fontFamily: 'Open Sans, sans-serif !important',
//     },
//     '& h6': {
//       fontFamily: 'Open Sans, sans-serif !important',
//       fontWeight: '400',
//       letterSpacing: '-0.01562em !important',
//       textTransform: 'uppercase',
//     },
//   },
// });

const LivabilitySection = props => {
  let { data, module_type } = props;
  const {
    // inViewport,
    forwardedRef,
    open_modal,
    propertyDetailsGallery,
    openLoginModal,
    city,
    cachedProject,
    callWishlistApi,
    wishlistPropertyDeletedCall,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
  } = props;

  const [access_token] = useAccessToken();
  const [user] = useUserInfo();
  module_type = module_type ?? 'buy';

  const { projects, loading } = props.apiData ??
    data ?? { projects: [], loading: false };

  const componentRef = useRef();
  const [weekday, setweekday] = useState();
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  const propertyDetailsClick = (
    property_id,
    property_name,
    locationwithoutCityName
  ) => {
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
      localityName: locationwithoutCityName,
    });
  };

  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      callBack: callWishlistApi,
    });
    wishlistPropertyDeletedCall();
  };

  const firstmenu = Menu(
    projects,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    toggleWishlist,
    openLoginModal,
    module_type,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger
  );

  // const classes = useStyles();


  const highlightedWord = word => {
    return <span className={classes.highlightWord}>{word}</span>;
  };

  const [limit] = useState(6);
  const [places, setplaces] = useState(0);
  const livabilityProjects = _.debounce(
    (get, moduletype, global_selected_city_id, skips, userId) => {
      const queryParams = {
        city_id: global_selected_city_id,
        skip: skips,
        limit: limit,
        module_type: moduletype,
        section: 'dealoftheweek',
        access_token: access_token,
        user_id: userId,
      };

      if (get) {
        props.getLivabilityProjects(queryParams);
      } else {
        props.nextLivabilityProjects(queryParams);
      }
    }
  );

  useEffect(() => {
    let mounted = false;
    let userId = user?.id;
    if (!mounted) {
      setplaces(0);
      livabilityProjects(true, module_type, city?.id, 0, userId);
    }
    return () => {
      mounted = true;
    };
  }, [city, module_type]);
  //get first day of week
  function getWeekBegin() {
    let now = new Date();
    let next = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (7 - now.getDay())
    );
    return next;
  }

  useEffect(() => {
    //convert date into required format
    function convert(str) {
      let date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      let endsOnDate = [day, months[mnth - 1], date.getFullYear()].join(' ');
      return endsOnDate;
    }

    let firstDay = getWeekBegin();
    let endsForDay = convert(firstDay);
    setweekday(endsForDay);
  }, [weekday]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      updateLivabilityProjects(projects);
    }

    return () => {
      mounted = true;
    };
  }, [projects]);

  const loadPrevProjects = () => {
    setplaces(prev => prev - 1);
    componentRef?.current?.handleArrowClick();
  };

  const loadNextProjects = () => {
    setplaces(prev => prev + 1);
    componentRef?.current?.handleArrowClickRight();
  };

  return projects.length > 0 ? (
    <div className={classes.livabilityData}>
      <section
        ref={forwardedRef}
        className="main-section"
        style={{
          maringTop: '37px',
        }}>
        <div className="container">
          <Grid container>
            <Grid
              item
              xs={9}
              justifyContent="flex-start"
              alignItems="center"
              container
              className={classes.livabilityHeading}>
              <CommonHeaderText
                text={
                  <>
                    {highlightedWord('Deal of The Week ')}in{' '}
                    {capitalizeFirstLetter(city?.name)}
                  </>
                }
                variant="h1"
              />
              <CommonHeaderText
                text={<> Ends on {weekday}</>}
                className={classes.subheading}
              />
            </Grid>
            {projects.length > 3 && (
              <Grid
                item
                xs={3}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginLeft: '-8px',
                }}>
                <div
                  style={{
                    paddingRight: '15px',
                    borderRight: '2px solid #17171721',
                  }}>
                  <ViewAll
                    text="View All"
                    url={getSearchPageURL(
                      searchURLType.dealOfWeek,
                      module_type,
                      city?.name
                    )}
                  />
                </div>
                <div
                  style={{
                    paddingLeft: '15px',
                  }}>
                  <HorizontalScrollButtons
                    loadPrevProjects={loadPrevProjects}
                    loadNextProjects={loadNextProjects}
                    disableNext={projects.length <= (places + 1) * 3}
                    disablePrev={places <= 0}
                  />
                </div>
              </Grid>
            )}
          </Grid>
          <Grid container className="card-section">
            <Grid
              item
              xs={12}
              // style={{ marginTop: '-19px' }}
            >
              {projects.length < 0 || loading ? (
                <>
                  <Grid
                    spacing={2}
                    container
                    justifyContent="center"
                    alignitems="center">
                    <Grid item xs={4}>
                      <FallbackCard />
                    </Grid>
                    <Grid item xs={4}>
                      <FallbackCard />
                    </Grid>
                    <Grid item xs={4}>
                      <FallbackCard />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <HorizontalScroll
                  itemClass="property-card-item MuiGrid-root MuiGrid-item MuiGrid-grid-xs-4"
                  componentRef={componentRef}
                  data={firstmenu}
                  dragging={false}
                />
              )}
            </Grid>
          </Grid>
          {/* )} */}
        </div>
      </section>
    </div>
  ) : (
    ''
  );
};

const Livability = handleViewport(LivabilitySection, { rootMargin: '-1.0px' });

const mapStateToProps = state => ({
  apiData: state.livabilityReducer,
  headersApiData: state.headerReducer,
  whishlistApiData: state.addWishlistReducer,
});

const mapDispatchToProps = {
  getLivabilityProjects,
  nextLivabilityProjects,
  addWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(Livability);
